.imgRecipients {
  max-height: 35em;
  padding-left: 1em;
}

figcaption{
  padding-bottom: 2em;
  text-align: center;
  font-size: 22px;
  font-weight: bolder;
}