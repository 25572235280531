.memberCards{
  justify-content: center;
  text-align: center;
  
}

.logos{
  height: 10em;
}

ul {
  font-size: 20px;
}

